.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App * {
  box-sizing: border-box;
}

.body {
  flex-grow: 1;
  background-color: #1a2327;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #cecece;
}

::-webkit-scrollbar-thumb {
  background: #2b2b2b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #202020;
}