.command-box {
    font-family: Inter, sans-serif;
    text-align: left;
    background-color: #151c1f;
    border-radius: 10px;
    width: 60%;
    padding: 10px;
    border: 1px #2f4650 solid;
    margin: 20px auto;
}

.command-name {
    display: block;
    color: white;
    font-size: 25px;
    margin-bottom: 10px;
}

.command-desc {
    color: rgb(219, 219, 219);
    font-size: 20px;
}


@media only screen and (max-width: 850px) {
    .command-box {
        width: 95%;
    }
}