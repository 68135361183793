.features-list {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    gap: 10px;
}

.features-section {
    font-family: Inter, sans-serif;
    color: white;
    flex: 1 0 40%;
    display: flex;
    position: relative;
    margin: 10px;
    justify-content: left;
    align-items: center;
    text-align: left;
    background-color: #1a2327;
    border-radius: 10px;
    width: fit-content;
}

.features-left {
    padding: 20px;
    background-color: rgb(28, 62, 78);
    border-radius: 50%;
}

.features-icon {
    width: 30px;
    height: 30px;
}

.features-right {
    padding-left: 20px;
}

.features-list-desc a {
    color: #5bbfec;
    text-decoration: none;
}

.features-list-desc a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 850px) {

    .features-section {
        width: 100%;
    }

    .demo-active {
        height: 50vw;
    }
}

@media only screen and (max-width: 700px) {
    .features-list {
        flex-direction: column;
    }
}