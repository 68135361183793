.temporarily-down {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
}

.temporarily-down a {
    color: #5bbfec;
    text-decoration: none;
}

.premium-gradient {
    background: linear-gradient(rgb(58, 133, 133) ,rgb(3, 252, 252));
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    margin-bottom: 20px;
}

.premium-title {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 120px;
    text-transform: uppercase;
    animation: glow 1s ease-in-out infinite alternate;

    margin: 0;
}

.premium-subtitle {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    margin: 0 5px;
}

.purchase-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    bottom: 100px;
    width: 75%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    border: 10px rgb(10, 136, 136) double;
    background-color: #191e20;
    color: white;
}

.purchase-title {
    font-size: 30px;
    margin-bottom: 0;
    margin: 0 auto;
    font-family: Montserrat, sans-serif;
}

.purchase-subtitle {
    color: rgb(219, 219, 219);
    margin-top: 0;
    font-family: Montserrat, sans-serif;
    margin: 0 auto;
    font-size: 20px;
}

.purchase-button-wrapper {
    margin: 0 auto;
    margin-top: 20px;
    text-decoration: none;
}

.purchase-button {
    background-color: #5865F2;
    border: none;
    display: flex;
    width: fit-content;
    align-items: center;
    color: white;
    font-family: Montserrat, sans-serif;
    cursor: pointer;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;

    transition: all ease 100ms;
}

.purchase-button:hover {
    background-color: #3a46c7;
    transform: scale(1.02, 1.02);
}

.premium-features-header {
    font-family: Inter, sans-serif;
    color: white;
    font-size: 40px;
}

.premium-features-list {
    margin-bottom: 30px;
}


@keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px rgb(41, 136, 214), 0 0 30px rgb(41, 136, 214), 0 0 40px rgb(41, 136, 214), 0 0 50px rgb(41, 136, 214), 0 0 60px rgb(41, 136, 214), 0 0 70px rgb(41, 136, 214);
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #00c2db, 0 0 40px #00c2db, 0 0 50px #00c2db, 0 0 60px #00c2db, 0 0 70px #00c2db, 0 0 80px #00c2db;
    }
}

@media only screen and (max-width: 1100px) {

    .purchase-section {
        width: 95%;
    }

    .purchase-top {
        display: flex;
        flex-direction: column;
    }

    .purchase-title,
    .purchase-subtitle {
        text-align: center;
    }
}

@media only screen and (max-width: 700px) {
    .premium-title {
        font-size: 18vw;
    }
}

@media only screen and (max-width: 520px) {
    .purchase-discord-login-button {
        flex-direction: column;
    }
    
    .purchase-discord-logo {
        margin-right: 0;
        margin-bottom: 20px;
    }
}