#privacy-policy-body {
    color: white;
    font-family: Inter, sans-serif;
    font-size: 20px;
}

.privacy-policy-title {
    font-family: Montserrat, sans-serif;
    font-size: 60px;
    text-transform: uppercase;
}

[data-custom-class='body'] {
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

[data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
}

[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    font-size: 14px !important;
}
[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
}
[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
}
[data-custom-class='body_text'], [data-custom-class='body_text'] * {
    font-size: 14px !important;
    font-family: Arial !important;
}
[data-custom-class='link'], [data-custom-class='link'] * {
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
    color: #3884ff;
    text-decoration: none;
}

[data-custom-class='link']:after {
    content: ' '
}

ul {
    list-style-type: square;
}
ul > li > ul {
    list-style-type: circle;
}
ul > li > ul > li > ul {
    list-style-type: square;
}
ol li {
    font-family: Arial ;
}