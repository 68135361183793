.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #161616;
    padding: 20px;
}

.footer-logo-anchor {
    display: block;
    max-width: 250px;
    padding: 10px;
    border-radius: 20px;

    transition: background-color 150ms;
}

.footer-legal-links {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-size: 10px;
}

.footer-legal-links a {
    margin: 0 10px;
    color: white;
}

.footer-logo-anchor:hover {
    background-color: #494949;
}

#footer-logo {
    max-width: 100%;
}

.footer h2 {
    color: white;
    font-family: Montserrat, sans-serif;
}

.footer-promos {
    display: flex;
    align-items: center;
}

.footer-promos * {
    margin: 0 5px;
    padding: 0;
}

@media only screen and (max-width: 720px) {
    .footer {
        flex-direction: column;
    }

    .footer-legal-links {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 440px) {
    .footer-promos {
        flex-direction: column;
    }
}