.commands-title {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 60px;
    text-transform: uppercase;
}

.commands-prefix-title {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    display: block;
    margin-bottom: 20px;
}

.commands-category-container {
    width: fit-content;
    margin: 0 auto;
    border-radius: 10px;
}

.commands-category {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    border: none;
    color: white;
    font-family: Inter, sans-serif;
    font-size: 30px;
    transition: background-color ease 150ms;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
}

.commands-category:hover {
    background-color: rgb(35, 92, 119);
}

.commands-category-active {
    background-color: rgb(35, 92, 119);
}


.commands-search-wrapper {
    display: inline-flex;
    align-items: center;
    border-left: 2px solid rgb(68, 116, 138);
    position: relative;
    top: 12px;
}

.commands-search-icon {
    display: inline-block;
    width: 40px;
    margin-left: 10px;
}

.commands-search {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    outline: none;
    margin-left: 10px;
    width: 100%;
    overflow: hidden;
}

.commands-divider {
    width: 80%;
    border: 1px rgb(68, 116, 138) solid;
}

.commands-category-dropdown-wrapper {
    display: none;
    position: relative;
    margin: 0 auto;
}

.commands-category-dropdown {
    appearance: none;
    background-color: rgb(35, 92, 119);
    color: white;
    font-family: Inter, sans-serif;
    font-size: 30px;
    border: none;
    border-radius: 10px;
    padding: 7px;
}

@media only screen and (max-width: 750px) {
    .commands-category {
        display: none;
    }

    .commands-category-dropdown-wrapper {
        display: block;
    }

    .commands-search-wrapper {
        margin: 20px auto;
        border: none;
        justify-content: center;
    }

    .commands-search-icon {
        display: inline-block;
        width: 40px;
        margin-left: 10px;
    }
    
    .commands-search {
        font-size: 7vw;
    }

    .commands-search-icon {
        width: 10%;
    }
}

@media only screen and (max-width: 550px) {
    .commands-title {
        font-size: 10vw;
    }

    .commands-prefix-title {
        font-size: 8vw;
    }
}