.banner-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../images/polka dot background.png');
    background-size: 900px;
    height: fit-content;
}

.banner {
    display: block;
    width: 75%;
    font-family: Montserrat, sans-serif;
    color: white;
    margin: 100px auto;
}

.banner-title {
    text-transform: uppercase;
    font-size: 100px;

    animation: pulse;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.banner-subtext {
    font-size: 30px;
}

.home-invite-button {
    margin: 0 auto;
    margin-top: 50px;
}

.demo-button {
    background-color:rgb(28, 62, 78);
    color: white;
    font-size: 30px;
    font-family: Inter, sans-serif;
    border-radius: 10px;
    border: none;
    padding: 20px;
    margin: 15px;
    cursor: pointer;
    transition: background-color ease 150ms;
}

.demo-button:hover {
    background-color:rgb(35, 92, 119);
}

.demo {
    margin: 20px;
    transition: height ease-in-out 150ms;
    border-radius: 10px;
    height: 0;
}

.demo-active {
    border: 5px rgb(35, 92, 119) solid;
    height: 40vw;
}

@media only screen and (max-width: 850px) {
    .demo-active {
        height: 50vw;
    }
}

@media only screen and (max-width: 700px) {
    .banner-title {
        font-size: 50px;
    }

    .demo-active {
        margin: 5px;
    }
}